
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
:root {
  --primary: #1d3e9bcc;
  --header-bg: #485d8f;
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  user-select: none;
}

.color-primary {
  color: var(--primary) !important;
}

.ant-menu-item-selected {
  background-color: #485d8f !important
}
/* table */


.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #adc3ec;
}

/* table */



/* body {
  overflow: auto;
} */
input[type="text"] {
  font-family: "Poppins", sans-serif;
}
label {
  font-weight: 500;
}


.navbar {
  height: 100%;
  display: flex;
  align-items: center;
 
 
  /* background-color: #fff; */
  /* position: sticky; */
  /* background */
  background: var(--header-bg);
  width: 100%;
}

:where(.css-dev-only-do-not-override-10t89zk).ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
:where(.css-dev-only-do-not-override-10t89zk).ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0;
}

.sidebar-custom-tab .ant-tabs-nav .ant-tabs-nav-list {
  display: block !important;
}

.sidebar-custom-tab .ant-tabs-nav .ant-tabs-nav-list > div {
  display: block !important;
}

.pl-5 {
  padding-left: 1.2rem;
}

.add-post {
  padding-left: 1rem;
}
.top-logo {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  width: 297px;
  background: #485d8f;
}
.top-logo img {
  padding: 5px;
  height: 100%;
}
.navbar .nav .dashboard-title {
  flex: 10;
  text-align: center;
  /* font-size: 2rem; */
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: italic;
  color: white;
  font-weight: bold;
}
.navtitle {
  font-size: 1.6rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #485d8f;
  color: #fff;
}
.nav {
  margin: 0 24px;
  height: 9vh;
  border-radius: 20px;
  /* background-color: #fff; */
}
.navtitle .dashboard-title span {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 2.2rem;
}
/* .content {
  background-color: #384b85;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
} */
/* .content h1 {
  font-family: "Baloo Thambi", cursive;
  position: relative;
  color: #10309b;
  font-size: 3.5rem;
  -webkit-text-stroke: 2px #18003a;
  text-transform: uppercase;
  text-shadow: -5px 5px 5px rgba(0, 0, 0, 0.25);
}
.content h1::before {
  font-family: "Baloo Thambi", cursive;
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: #2192ff;
  overflow: hidden;
  animation: animate 4s linear infinite;
}
@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
} */
.dashboard-container {
  background-color: #f2f2f2;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  border: 1px solid #ddd;
  background: #ddd;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #1d3e9bcc;
  border-radius: 10px;
  border: 2px solid #ddd;
}
.add,
.stepform,
.fill .form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  background-color: transparent;
  height: auto;
}
.ant-drawer-header {
  background-color: rgba(29, 62, 155, 0.05);
  /* border-radius: 20px; */
  /* margin: 15px 24px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.ant-drawer-header svg {
  height: 20px;
  width: 20px;
  padding: 3px;
  border-radius: 20%;
  background-color: rgba(29, 62, 155, 0.01);
}
.fill .form-container,
.leaderform {
  background-color: rgba(29, 62, 155, 0.05);
  border-radius: 20px;
  padding: 25px;
  padding-bottom: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.formContainer {
  width: 100%;
  border: 2px solid #e2e2e2;
  border-radius: 24px;
  padding: 0 30px;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: rgba(29, 62, 155, 0.05);
}
.button-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.img img {
  margin: auto;
  right: 0;
  left: 0;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin-bottom: 20px;
}
.title {
  font-family: "Poppins", sans-serif;
  text-align: center;
  letter-spacing: 2px;
}
.leadership,
.event,
.add-post,
.suggestion,
.gallery,
.twitter,
.facebook-c,
.youtube,
.live,
.joinmember {
  width: 98.5%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.text {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
}
.selected-item {
  border: 2px solid #eee;
  border-radius: 24px;
  padding: 30px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
  text-align: justify;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  gap: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.selected-item img {
  height: 250px;
  border: 2px solid #1d3f9bcc;
  padding: 5px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.selected-item .quote {
  background-color: #eee;
  padding: 5px 10px;
  border-left: 3px solid #4267b2;
  font-style: italic;
  width: 80%;
  margin: 20px auto;
  right: 0;
  left: 0;
  color: #777;
  font-size: 16px;
}
.details {
  overflow: scroll;

  overflow-y: hidden;
}
.details,
.post-details {
  border: 2px solid #eee;
  padding: 10px;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.small-heading {
  color: black !important;
}

.news-para {
  margin-top: 4px;
  color: #676161;
}
.post-display {
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-details {
  width: 100%;
  margin-top: -20px;
}
.post-details .custom-table {
  border-spacing: 4px;
}
.post-details .custom-table td {
  padding: 5px;
}
.custom-table .tag {
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.custom-table .tag:hover {
  opacity: 0.5;
}
.custom-table {
  /* border-collapse: collapse; */
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  /* table-layout: fixed; */
  border-radius: 8px;
  border-spacing: 5px;
}
.custom-table th,
.custom-table td {
  border: 1px solid #ccc;
  padding: 6px 10px;
  text-align: center;
  border-radius: 6px;
  word-wrap: break-word;
}

/* #root {
  height: 100%;
  overflow: hidden;
} */
.custom-table th {
  background: rgba(29, 62, 155, 0.65);
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 10px;
}

.primary {
  background: rgba(29, 62, 155, 0.65) !important;
  color: white !important;
}
.custom-table .actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.custom-table tr:hover {
  background-color: #f5f5f5;
}
.icons {
  font-size: 20px;
  margin-right: 5px;
}
.icon-p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  color: #1d3e9bcc;
}
.bottom-line {
  background-color: rgba(29, 62, 155, 0.05);
  /* border-radius: 20px; */
  padding: 20px 0;
  margin: -20px -24px 0 -24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
.update-modal svg,
.add-item-modal svg,
.add-modal-form svg .update-modal-form svg {
  height: 20px;
  width: 20px;
  padding: 3px;
  border-radius: 20%;
  background-color: rgba(29, 62, 155, 0.01);
}
.update-modal-form,
.add-modal-form {
  background-color: rgba(29, 62, 155, 0.05);
  margin-top: 20px;
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  /* background-image: url("/home/djt/Documents/DJT Projects/partydashboard/src/images/istockphoto-624878906-170667a (1).png");
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: #1d3e9bcc;
  background-blend-mode: multiply;
  filter: grayscale(0.2);
  font-size: 16px;
  color: #fff;
}
.clock .span span {
  margin: 0 1.5px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .iframe {
  width: 32%;
  height: calc(100vh - 280px);
  border: 2px solid #eee;
  border-radius: 1em;
  padding: 25px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
}
.wrapper .iframe p {
  position: absolute;
  bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  left: 215px;
}
.wrap {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-bottom: 1em;
}
.wrap iframe {
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 1em;
  padding: 15px;
  width: 480px;
  height: 270px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.wrap .header {
  width: 67%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-container .ant-tabs-nav-list {
  /* background-image: url("/home/djt/Documents/DJT Projects/partydashboard/src/images/istockphoto-624878906-170667a.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -1090px;
  background-color: #1d3e9bcc;
  background-blend-mode: multiply;
  filter: grayscale(0.2);
  color: #fff;
  height: 100%;
  width: 250px;
  padding-top: 15px;
}
.gallery .ant-tabs-nav-list {
  background-color: transparent;
  color: #000;
}

:where(.css-dev-only-do-not-override-10t89zk).ant-tabs
  > .ant-tabs-nav
  .ant-tabs-nav-wrap,
:where(.css-dev-only-do-not-override-10t89zk).ant-tabs
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-wrap {
  overflow: inherit;
}
.ant-tabs-nav .ant-tabs-nav-list {
  /* height: 740px !important; */
  overflow: scroll !important;
}

/* :where(.css-dev-only-do-not-override-10t89zk).ant-tabs-left > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-10t89zk).ant-tabs-right > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-10t89zk).ant-tabs-left
  > div
  > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-10t89zk).ant-tabs-right
  > div
  > .ant-tabs-nav {
  flex-direction: inherit;
} */

:where(.css-dev-only-do-not-override-10t89zk).ant-tabs
  > .ant-tabs-nav
  .ant-tabs-nav-operations,
:where(.css-dev-only-do-not-override-10t89zk).ant-tabs
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-operations {
  display: none;
}

.gallery .ant-tabs-nav-list {
  height: inherit !important;
  overflow: inherit !important;
}

.ant-tabs-content-holder {
  height: 100vh;
  overflow: scroll;
}
.gallery .ant-tabs-content-holder {
  height: calc(100vh - 300px) !important;
  width: 100% !important;
  /* overflow: hidden !important; */
}
.gallery .ant-tabs-tab-active {
  background-color: transparent !important;
}

.custom-tabs .ant-tabs-content-holder {
  /* height: calc(100vh - 98px);
  max-height: calc(100vh - 98px); */
  overflow: auto;
  padding: 10px 0;
  z-index: 99;
  background: #f2f2f2;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 1em;
  border-bottom: 2px solid #ccc;
}
.event .ant-tabs-content-holder {
  height: calc(100vh - 250px);
}
.event .ant-tabs-nav-list {
  background-color: transparent;
  color: #000;
  padding: 0;
}

.news-tab .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%;
}
.facebook-container {
  height: calc(100vh - 330px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
.facebook {
  background-color: #fff;
  border: 1px solid #ddd;
  outline: none;
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 14px;
  cursor: pointer;
  color: #000;
  transition: 0.2s;
  font-weight: 500;
}
.facebook:hover {
  color: #4267b2;
  border-color: #4267b2;
}
.toast {
  border: 2px solid #005bea;
  padding: 8px 16px;
  color: #005bea;
  font-weight: 500;
}
.error {
  border: 2px solid #ff0000;
  padding: 8px 16px;
  color: #ff0000;
  font-weight: 500;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.read-more-button {
  cursor: pointer;
  font-weight: 500;
}
.read-more-dots {
  letter-spacing: 2px;
}
.dash-span {
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #fff !important;
}
.dash-span img {
  height: 23px;
  margin-right: 10px;
}
.dash-icon {
  margin-right: 10px;
}
.text-btn {
  background-color: transparent;
  border: none;
  outline: none;
}
.label-text {
  color: #1d3f9bbb;
  font-weight: 500;
  font-size: 16px;
}
.p-icon {
  text-align: center;
}
.p-icon .delete-icon {
  color: #ff3f56;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 25px;
  width: 30px;
  padding: 7px;
  padding-bottom: 10px;
  background-color: #fff5f6;
  border-radius: 50%;
  margin-bottom: 1em;
}
.delete-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5em;
  padding: 0 2.5em;
}
.delete-span {
  font-size: 18px;
}
/* .delete-modal .ant-btn:nth-child(1),
.delete-modal .ant-btn:nth-child(2) {
  width: 47%;
  border-radius: 30px;
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  background: #f2f2f2;
  border: none;
} */
/* .deleted-icon .ant-btn:nth-child(2) {
  background: #1d3e9b;
}
.delete-modal .ant-btn:nth-child(2) {
  color: #fff;
  background: #ff3f56;
} */
.ant-tabs-tab-active {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-radius: 6px !important;
}
.ant-upload {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}
ul.ant-card-actions {
  position: absolute;
  top: -140px;
  right: 5px;
  gap: 150px;
  height: 70%;
  border: none !important;
  display: flex;
  border-radius: 0 !important;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent !important;
}
.tweet-area {
  border: 2px solid #e2e2e2;
  padding: 24px;
  /* padding-top: 0; */
  background-color: #fff;
  width: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.voter-Data {
  width: 100%;
  /* display: flex;
    justify-content: center; */
}

.download-sample-file {
  display: flex;
  justify-content: center;
  font-size: large;
  margin-bottom: 6%;
  color: blue;
  cursor: pointer;
}

.filter-button {
  background-color: rgba(29, 62, 155, 0.65);
  color: white;
  font-size: 16px;
}

.pagination-style {
  display: flex;
  justify-content: end;
  margin-top: 1.5%;
  margin-bottom: 1%;
  background-color: rgba(29, 62, 155, 0.05);
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  border-radius: 12px;
}

.pagination-core {
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.pressNotepagination{
  display: flex;
  justify-content: end;
  background-color: #d8d7d7;
  border-radius: 5px;

}

.icons-style {
  display: flex;
  justify-content: end;
}

.journey-year {
  /* background-color: #eee; */
  color: #777;
  font-size: 16px;
}

.zero-events-text {
  display: flex;
  justify-content: center;
  font-size: large;
  font-weight: inherit;
}

/* feedback design */

.feedbackMessage {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.feedbackMessage span {
  font-size: 16px;
}

.footer-static {
  color: #f2f2f2;
}

.select-box-width {
  width: 100%;
}
.filter-button-data {
  background-color: #1d3e9bcc;
  color: white;
}

.filter-button-div {
  display: flex;
  justify-content: end;
  margin-top: 1%;
}

.voter_null_data {
  display: flex;
  justify-content: center;
  font-size: large;
  font-weight: bolder;
}
/* 
.ant-tabs-nav-wrap{
  overflow: none;
} */

.parent-navbar .ant-tabs-nav-list{
overflow-y: auto;
height: 100vh;
}

@media only screen and (max-width: 600px) {
  .nav {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    text-align: center;
  }

  .dashboard-title {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  .navbar {
    font-size: 10%;
  }

  .ant-btn {
    margin-top: 10px; /* Add space between the title and the button */
  }
  .navbar {
  }
}

.contain {
  height: 100vh;
}
.form,
.logout-container {
  height: calc(100vh - 165px);
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f2f2f2;
}
.merger {
  height: 75vh;
  width: 60vw;
  padding: 30px 50px;
  border-right: 2px solid #ccc;
}
.merger .merge {
  width: 100%;
  text-align: center;
}
.content-style {
  margin: 0;
  height: 65vh;
}
.content-style img {
  width: 100%;
  height: 100%;
}
.form-contain {
  height: 70vh;
  /* width: 40vw; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.form .container {
  border: 2px solid #eee;
  zoom: 1.15;
  width: 80%;
  background-color: #fff;
  padding: 2rem 3rem 0 3rem;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.container .headers {
  margin-bottom: 40px;
  text-align: center;
}
.container .headers h2 {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 28px;
}
.container .headers span {
  color: #1d3e9bcc;
  text-transform: uppercase;
}
.container label {
  font-weight: 500;
}
input[type="text"],
input[type="email"],
input[type="number"] {
  font-family: "Poppins", sans-serif;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
  margin: 0;
}
.msg {
  color: #f00;
  margin-bottom: 20px;
}
.msg.success {
  color: #2ea94a;
}

.stepform .form-container {
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.stepform form h1 {
  font-size: 2.5rem;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -30px -50px 22px -50px;
  /* margin-bottom: 22px; */
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #1d3e9b;
  background-color: rgba(29, 62, 155, 0.05);
  border-radius: 20px 20px 0 0;
}
.name-tag {
  display: grid;
  grid-template-columns: 2fr 10fr;
  gap: 20px;
}
.stepform form label {
  font-weight: 500;
}
.stepform .otp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stepform form {
  background: #fff;
  padding: 30px 50px 0 50px;
  border-radius: 20px;
  height: auto;
  width: 650px;
  scroll-behavior: auto;
  overflow-y: auto;
}
.stepform .form .agreement {
  display: flex;
  align-items: flex-start;
}
#personal-details-1 .name-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.stepform #personal-details-2 {
  display: none;
  width: 100%;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
  margin: 0;
}
.stepform .state,
.stepform .assembly,
.stepform .details2 {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 30px;
}
.stepform h2 {
  margin-bottom: 15px;
  font-size: 24px;
}
.stepform .button-grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stepform .error {
  padding: 0 50px;
  color: #f00;
  margin-bottom: 15px;
  border: none;
  font-weight: 400;
}
.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .stepform form {
    padding: 10px;
  }
  .stepform form h1 {
    font-size: 2rem;
  }
  .name-tag {
    grid-template-columns: 1fr;
  }
  .stepform .otp {
    gap: 40px;
  }
}
@media (min-width: 601px) and (max-width: 768px) {
  .stepform form {
    width: 90%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .stepform form {
    width: 80%;
  }
}
@media (min-width: 1025px) {
  .stepform form {
    width: 650px;
  }
}
